/* eslint-disable sonarjs/no-duplicate-string */

import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_REQUEST } from "@ngrx/router-store";
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, delay, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { exitOverlayActions } from "@cg/olb/exit-overlay";
import {
  AppointmentActions,
  AppointmentFacade,
  AppointmentSelectors,
  ChannelSwitchFacade,
  ContactDataActions,
  ContactDataSelectors,
  ContextActions,
  CustomerCaseActions,
  CustomerCaseFacade,
  CustomerCaseSelectors,
  DamageActions,
  DamageFacade,
  DamageSelectors,
  InsuranceActions,
  InsuranceFacade,
  ProcessActions,
  ProcessFacade,
  ProcessSelectors,
  ProductActions,
  ProductFacade
} from "@cg/olb/state";
import { ResumeFacade, ResumeService } from "@cg/resume-core";
import * as Sentry from "@sentry/angular-ivy";
import { SeverityLevel } from "@sentry/types";
import { CookieService } from "ngx-cookie-service";
import { TrackingService } from "@cg/analytics";
import { ResumeApiRequest } from "@cg/carglass-shared-state";
import { isOpportunityFunnel } from "@cg/core/utils";
import {
  ArrowHintService,
  isDirectResumeFn,
  OLB_PROCESS_FLOW_MODEL,
  ProcessFlow,
  ProcessProgressService,
  Product,
  ScrollService
} from "@cg/olb/shared";
import {
  Appointment,
  ChosenProduct,
  CustomerCase,
  DamageLocation,
  DamageSize,
  DamageType,
  DamageWindow,
  Lpn,
  ProcessId,
  ProcessMetadata,
  RequiredService,
  Resume
} from "@cg/shared";

@Injectable()
export class ProcessEffects {
  public scrollToTile$ = createEffect(
    () =>
      ({ timeToDelay = 600 }: { timeToDelay?: number } = {}) =>
        this.actions$.pipe(
          ofType(ProcessActions.scrollToTile),
          delay(timeToDelay),
          tap(({ payload }: { payload: ProcessId }) => {
            if (payload !== "damage-window") {
              this.scrollService.scrollToTileId(payload);
            }
          }),
          tap(({ payload }: { payload: ProcessId }) =>
            Sentry.addBreadcrumb({
              category: "olb-process-id",
              message: payload,
              level: "info"
            })
          )
        ),
    { dispatch: false }
  );

  public handleArrowHint$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(ProcessActions.setCurrentProcess),
        tap(({ payload }: { payload: ProcessId }) => {
          if (["damage-window", "damage-type", "damage-location", "damage-size"].includes(payload)) {
            this.arrowHintService.attachArrow();
          } else {
            this.arrowHintService.detachArrow();
          }
        })
      ),
    { dispatch: false }
  );

  public scrollEnterOlb$ = createEffect(
    () =>
      // delay is important for correct analytics dataLayer order
      ({ timeToDelay = 600 }: { timeToDelay?: number } = {}) =>
        this.actions$.pipe(
          ofType(ProcessActions.enterOlb),
          delay(timeToDelay),
          withLatestFrom(this.processFacade.currentProcessId$),
          tap(([_, processId]: [Action, ProcessId]) => this.processFacade.setCurrentProcessId(processId)),
          filter(([_, processId]: [Action, ProcessId]) => processId !== "damage-window"),
          tap(([_, processId]: [Action, ProcessId]) => {
            this.scrollService.scrollToTileId(processId);
          })
        ),

    { dispatch: false }
  );

  public enterOlb$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(ProcessActions.enterOlb),
        withLatestFrom(this.resumeFacade.resumeResponse$),
        filter(([_, resumeResponse]: [Action, Resume]) => !resumeResponse),
        tap(() => {
          const resumeId = this.cookieService.get("resumeId");
          if (resumeId) {
            this.router.navigate(["/login/" + resumeId]);
          }
        })
      ),
    { dispatch: false }
  );

  public requestFailures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerCaseActions.fetchCustomerCaseFailure,
        CustomerCaseActions.checkDuplicateFailure,
        CustomerCaseActions.confirmCustomerCaseFailure,
        CustomerCaseActions.updateCustomerCaseFailure,
        ContextActions.createContextFailure,
        InsuranceActions.loadInsurancesFailure,
        AppointmentActions.confirmAppointmentFailure,
        AppointmentActions.getAppointmentsFailure,
        AppointmentActions.validateAppointmentDateFailure,
        ProductActions.fetchAllBuildDatesFailure,
        ProductActions.fetchAllManufacturersFailure,
        ProductActions.fetchAllModelsFailure,
        ProductActions.fetchAllProductsFailure,
        ProductActions.fetchAllTypesFailure,
        ProductActions.setChosenProductFailure,
        ContactDataActions.updateCustomerContactFailure,
        DamageActions.updateDamageFailure
      ),
      tap((action: Action & { error: string }) => {
        Sentry.addBreadcrumb({
          category: "technical-error",
          message: action.type,
          level: "error" as SeverityLevel
        });
        Sentry.captureMessage(action.error, "error" as SeverityLevel);
      }),
      map(({ error }: Action & { error: string }) => error),
      map((error: string) => ProcessActions.setTechnicalError({ error }))
    )
  );

  public technicalError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.setTechnicalError),
      withLatestFrom(this.processFacade.currentProcessId$),
      map(([_, currentProcessId]: [{ error: string } & Action<"[Process] Set Technical Error">, ProcessId]) => ({
        currentProcessId
      })),
      switchMap(({ currentProcessId }: { currentProcessId: ProcessId }) => [
        ProcessActions.setProcessMetaData({ payload: { id: currentProcessId, valid: true } }),
        ProcessActions.setProcessMetaData({ payload: { id: "olb-technical-error", valid: false } }),
        ProcessActions.setCurrentProcess({ payload: "olb-technical-error" })
      ])
    )
  );

  public funnelCompletedSuccessfully$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.setCurrentProcess),
      withLatestFrom(this.processFacade.processMetaData$),
      filter(
        ([{ payload }, processMetaData]: [{ payload: ProcessId }, ProcessMetadata[]]) =>
          payload === "appointment-confirmation" ||
          payload === "opportunity-funnel-success" ||
          (payload === "opportunity-funnel-channel-switch" &&
            processMetaData.some(({ id }: Pick<ProcessMetadata, "id">) => id === "opportunity-funnel-summary"))
      ),
      tap(() => this.scrollService.scrollToPositionOnApp({ left: 0, top: 0 }, "instant")),
      withLatestFrom(this.processFacade.processMetaData$),
      map(([_, processMetaData]: [[{ payload: ProcessId }, ProcessMetadata[]], ProcessMetadata[]]) =>
        processMetaData.slice(-1)
      ),
      map((processMetaData: ProcessMetadata[]) => ProcessActions.funnelCompleted({ payload: processMetaData }))
    )
  );

  public routeChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      withLatestFrom(
        this.processFacade.exitOlb$,
        this.processFacade.funnelActive$,
        this.processFacade.currentProcessId$
      ),
      filter(
        ([_action, exit, active, _currentProcessId]: [
          Action & { payload: { event: { url: string } } },
          boolean,
          boolean,
          ProcessId
        ]) => exit === false && active === true
      ),
      map(
        ([action, _exit, _active, currentProcessId]: [
          Action & { payload: { event: { url: string } } },
          boolean,
          boolean,
          ProcessId
        ]) => ({
          action,
          currentProcessId
        })
      ),
      withLatestFrom(
        this.channelSwitchFacade.isConnectedToAgent$,
        this.channelSwitchFacade.isTimerDone$,
        this.channelSwitchFacade.isCallbackLate$
      ),
      switchMap(
        ([{ action, currentProcessId }, isConnectedToAgent, isTimerDone, isCallbackLate]: [
          { action: Action & { payload: { event: { url: string } } }; currentProcessId: ProcessId },
          boolean,
          boolean,
          boolean
        ]) =>
          currentProcessId === "damage-window" ||
          currentProcessId === "appointment-confirmation" ||
          currentProcessId === "opportunity-funnel-success" ||
          (isCallbackLate && isTimerDone && !isConnectedToAgent)
            ? [ProcessActions.exitOlb(), ProcessActions.setUrlToNavigate({ payload: action.payload.event.url })]
            : [
                exitOverlayActions.openExitOverlay(),
                ProcessActions.setUrlToNavigate({ payload: action.payload.event.url })
              ]
      )
    )
  );

  public confirmExitOverlay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exitOverlayActions.confirm),
      map(() => ProcessActions.exitOlb())
    )
  );

  public exitOlb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.exitOlb),
      switchMap(() => [exitOverlayActions.closeExitOverlay(), ProcessActions.navigate()])
    )
  );

  public navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.navigate),
        withLatestFrom(this.processFacade.getUrlToNavigateAfterExit$),
        map(([_, url]: [Action, string]) => url),
        filter((url: string) => !!url),
        tap((url: string) => this.router.navigate([url]))
      ),
    {
      dispatch: false
    }
  );

  public enterInsuranceResultInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.setCurrentProcess),
        filter(({ payload }: { payload: ProcessId }) => ["appointment"].includes(payload)),
        withLatestFrom(
          this.insuranceFacade.gdvVin$,
          this.insuranceFacade.vin$,
          this.damageFacade.requiredService$,
          this.damageFacade.selectedDamage$,
          this.productFacade.selectedManufacturer$,
          this.productFacade.selectedModel$,
          this.productFacade.selectedBuildDate$,
          this.productFacade.selectedModelType$
        ),
        tap(
          ([
            _,
            gdvVin,
            vin,
            service,
            damage,
            selectedManufacturer,
            selectedModel,
            selectedBuildDate,
            selectedModelType
          ]: [
            { payload: ProcessId },
            string,
            string,
            RequiredService,
            DamageWindow,
            string,
            string,
            string,
            string
          ]) => {
            const isCarManuallySelected =
              !!selectedManufacturer && !!selectedModel && !!selectedBuildDate && !!selectedModelType;
            if (!!gdvVin && !vin && !isCarManuallySelected) {
              this.productFacade.loadAllProducts({
                customerCaseId: "",
                entryChannel: "",
                vin: gdvVin,
                productType: service,
                part: damage,
                brand: "",
                model: "",
                carType: "",
                buildDate: ""
              });
            }
          }
        )
      ),
    {
      dispatch: false
    }
  );

  public restorePassThroughNextTileId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.rewindProcess),
        withLatestFrom(this.processFacade.processMetaData$),
        tap(
          ([{ payload }, processMetaData]: [
            { payload: ProcessId } & Action<"[Process] Rewind Step">,
            ProcessMetadata[]
          ]) => {
            const stepProcessMetadata = processMetaData.find((metaData: ProcessMetadata) => metaData.id === payload);
            if (stepProcessMetadata?.passthroughExitId) {
              this.processFacade.setPassthroughNextTileId(stepProcessMetadata.passthroughExitId);
            }
          }
        )
      ),
    { dispatch: false }
  );

  public resetOpportunityFunnelCase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.rewindProcess),
        withLatestFrom(this.processFacade.processMetaData$),
        filter(
          ([_, processMetaData]: [{ payload: ProcessId } & Action<"[Process] Rewind Step">, ProcessMetadata[]]) =>
            !isOpportunityFunnel(processMetaData)
        ),
        tap(() => {
          const propertyState = this.trackingService.eventPropertyState;
          const opportunityCase = propertyState["opportunity-funnel"].case;

          if (!opportunityCase || opportunityCase === "not-set") {
            return;
          }

          this.trackingService.trackEvent({
            eventAction: "opportunity-funnel-entry",
            eventLabel: "leave-funnel",
            ...{
              "opportunity-funnel": {
                case: "not-set"
              }
            }
          });
        })
      ),
    {
      dispatch: false
    }
  );

  public loadProductsOnHukDirectResume$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DamageActions.setDamageType, DamageActions.setLocateDamage, DamageActions.setDamageSize),
        withLatestFrom(
          this.insuranceFacade.vin$,
          this.damageFacade.selectedDamage$,
          this.customerCaseFacade.customerCaseId$,
          this.processFacade.currentProcessId$
        ),
        filter(
          ([_damageType, _vin, _damage, _customerCaseId, currentProcessId]: [
            unknown,
            unknown,
            unknown,
            unknown,
            ProcessId
          ]) => currentProcessId !== "channel-switch"
        ),
        tap(
          ([damageType, vin, damage, customerCaseId, _currentProcessId]: [
            (
              | ({ payload: DamageType } & Action<"[Damage] Set Crack Amount">)
              | ({ payload: DamageLocation } & Action<"[Damage] Set Locate Damage">)
              | ({ payload: DamageSize } & Action<"[Damage] Set Size">)
            ),
            string,
            DamageWindow,
            string,
            ProcessId
          ]) => {
            let productType = "";
            switch (damageType.type) {
              case DamageActions.setDamageType.type:
                productType =
                  damageType.payload === DamageType.MINOR ? RequiredService.REPAIR : RequiredService.REPLACE;
                break;
              case DamageActions.setLocateDamage.type:
                productType =
                  damageType.payload === DamageLocation.OTHER ? RequiredService.REPAIR : RequiredService.REPLACE;
                break;
              case DamageActions.setDamageSize.type:
                productType =
                  damageType.payload === DamageSize.SMALL ? RequiredService.REPAIR : RequiredService.REPLACE;
                break;
              default:
                break;
            }
            if (isDirectResumeFn(this.olbConfig.entryChannel)) {
              this.productFacade.loadAllProducts({
                customerCaseId,
                entryChannel: "",
                vin: vin,
                productType,
                part: damage,
                brand: null,
                model: null,
                buildDate: null
              });
            }
          }
        )
      ),
    {
      dispatch: false
    }
  );

  public progressPercentage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.setCurrentProcess),
      withLatestFrom(this.processFacade.processMetaData$),
      map(([currentProcess, processMetaData]: [{ payload: ProcessId } & Action<string>, ProcessMetadata[]]) => {
        const progressPercentage = this.processProgressService.getProgressForProcessId(
          this.processFlow,
          currentProcess.payload,
          processMetaData
        );
        return ProcessActions.setProcessPercentage({ payload: progressPercentage });
      })
    )
  );

  public closeEditOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.closeEditOverlay),
        withLatestFrom(this.processFacade.processMetaData$),
        tap(([{ processId }, processMetaData]: [{ processId: ProcessId }, ProcessMetadata[]]) => {
          this.resetAppointmentsIfProcessIdIsBeforeAppointmentProcessId(processId, processMetaData);
          this.processFacade.setCurrentProcessId(processId);
          this.processFacade.rewindToExistingProcessId(processId);
        })
      ),
    { dispatch: false }
  );

  public saveRestoreState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.saveRestoreState, ProcessActions.saveRestoreStateB2b),
      withLatestFrom(
        this.store.select(ProcessSelectors.getProcessMetaData),
        this.store.select(CustomerCaseSelectors.getCustomerCase),
        this.store.select(ContactDataSelectors.getEmail),
        this.store.select(DamageSelectors.getLpn),
        this.store.select(ProcessSelectors.getResumeId),
        this.store.select(AppointmentSelectors.getSelectedAppointment)
      ),
      map(
        ([action, processMetaData, customerCase, customerEmail, lpn, storedResumeId, appointment]: [
          (
            | ({ correlationId?: string; optIn: boolean } & Action<"[Process/SaveRestore] Save for Restore B2C">)
            | ({
                correlationId?: string;
                overwriteRequest: Partial<ResumeApiRequest>;
              } & Action<"[Process/SaveRestore] Save For Restore B2B">)
          ),
          ProcessMetadata[],
          CustomerCase,
          string,
          Lpn,
          string,
          Appointment
        ]) => ({
          action,
          processMetaData,
          customerCase,
          customerEmail,
          lpn,
          storedResumeId,
          appointment
        })
      ),
      filter(({ customerCase }: { customerCase: CustomerCase }) => !!customerCase),
      switchMap(
        (result: {
          action:
            | ({ correlationId?: string; optIn: boolean } & Action<"[Process/SaveRestore] Save for Restore B2C">)
            | ({
                correlationId?: string;
                overwriteRequest: Partial<ResumeApiRequest>;
              } & Action<"[Process/SaveRestore] Save For Restore B2B">);
          customerCase: CustomerCase;
          lpn: Lpn;
          storedResumeId: string;
          customerEmail: string;
          processMetaData: ProcessMetadata[];
          appointment: Appointment;
        }) => {
          const customerCase = result.customerCase;
          const lpn = result.lpn;
          const action = result.action;
          const storedResumeId = result.storedResumeId;

          const resumeRequestTemplate: Omit<ResumeApiRequest, "optIn"> = {
            customerCaseId: customerCase.id,
            customerEmail: result.customerEmail,
            validationSecret: `${lpn.region}-${lpn.letters}-${lpn.numbers}`,
            entryChannel: customerCase.entryChannel,
            version: 1.0,
            state: {
              processMetaData: result.processMetaData,
              appointment: result.appointment,
              trackingProperties: this.trackingService.eventPropertyState
            }
          };

          let resumeRequest: ResumeApiRequest;

          if (action.type === ProcessActions.saveRestoreState.type) {
            resumeRequest = {
              ...resumeRequestTemplate,
              optIn: action.optIn
            } as ResumeApiRequest;
          } else if (action.type === ProcessActions.saveRestoreStateB2b.type) {
            resumeRequest = {
              ...resumeRequestTemplate,
              ...action.overwriteRequest,
              optIn: true,
              state: {
                ...resumeRequestTemplate.state,
                ...action.overwriteRequest.state
              }
            };
          }

          const request: Observable<string> = storedResumeId
            ? this.resumeService.updateResume(storedResumeId, resumeRequest)
            : this.resumeService.createResume(resumeRequest);

          const { correlationId } = action;

          return request.pipe(
            map((resumeId: string) => ProcessActions.saveRestoreStateSuccess({ resumeId, correlationId })),
            catchError((error: HttpErrorResponse) =>
              of(ProcessActions.saveRestoreStateFailure({ error, correlationId }))
            )
          );
        }
      )
    )
  );

  public setChosenProductOnChannelSwitch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.setCurrentProcess),
      withLatestFrom(
        this.customerCaseFacade.customerCaseId$,
        this.productFacade.products$,
        this.damageFacade.selectedDamage$
      ),
      filter(
        ([{ payload }, customerCaseId, _products, __]: [{ payload: ProcessId }, string, Product[], DamageWindow]) =>
          ["channel-switch", "opportunity-funnel-channel-switch"].includes(payload) && !!customerCaseId
      ),
      map(([_, customerCaseId, products, damageWindow]: [{ payload: ProcessId }, string, Product[], DamageWindow]) => {
        let productArray: ChosenProduct[];
        if (products?.length >= 1 && damageWindow === DamageWindow.FRONT) {
          productArray = [
            {
              itemNumber: products[0].carglassNr,
              quantity: 1
            }
          ];
        } else {
          productArray = [];
        }

        return ProductActions.setChosenProduct({
          payload: {
            id: customerCaseId,
            product: productArray
          }
        });
      })
    )
  );

  public resetAppointmentStateOnGoBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.goBack),
        filter(({ payload }: { payload: ProcessId }) => payload === "appointment"),
        withLatestFrom(this.processFacade.processMetaData$),
        map(([_, processMetaData]: [{ payload: ProcessId }, ProcessMetadata[]]) => {
          const goToProcessId = processMetaData.slice(-2, -1)[0].id;
          this.resetAppointmentsIfProcessIdIsBeforeAppointmentProcessId(goToProcessId, processMetaData);
        })
      ),
    { dispatch: false }
  );

  // eslint-disable-next-line max-params
  public constructor(
    private readonly channelSwitchFacade: ChannelSwitchFacade,
    private readonly actions$: Actions,
    private readonly scrollService: ScrollService,
    private readonly processFacade: ProcessFacade,
    private readonly router: Router,
    private readonly arrowHintService: ArrowHintService,
    private readonly insuranceFacade: InsuranceFacade,
    private readonly productFacade: ProductFacade,
    private readonly damageFacade: DamageFacade,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly resumeFacade: ResumeFacade,
    private readonly store: Store,
    private readonly resumeService: ResumeService,
    private readonly trackingService: TrackingService,
    private readonly cookieService: CookieService,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly processProgressService: ProcessProgressService,
    @Inject(OLB_CONFIG) private readonly olbConfig: OlbConfiguration,
    @Inject(OLB_PROCESS_FLOW_MODEL) private readonly processFlow: ProcessFlow
  ) {}

  private resetAppointmentsIfProcessIdIsBeforeAppointmentProcessId(
    processId: string,
    previousProcessMetadata: ProcessMetadata[]
  ) {
    const appointProcessIdIndex = previousProcessMetadata.findIndex((metaData: ProcessMetadata) =>
      /^tesla-appointment$|^appointment$/.test(metaData.id)
    );
    if (appointProcessIdIndex < 0) {
      return;
    }

    const processIdIndex = previousProcessMetadata.findIndex((metaData: ProcessMetadata) => metaData.id === processId);

    if (processIdIndex < appointProcessIdIndex) {
      this.appointmentFacade.resetState();
    }
  }
}
