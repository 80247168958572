import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ContactDataActions, ContactDataFacade, CustomerCaseActions, CustomerCaseFacade } from "@cg/olb/state";
import { errorToString } from "@cg/core/utils";
import {
  CustomerCase,
  CustomerObjects,
  CustomerService,
  UpdateCustomerOpportunityFunnelRequest,
  UpdateCustomerRequest
} from "@cg/shared";

@Injectable()
export class ContactDataEffects {
  public createOrUpdateCustomerContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactDataActions.updateCustomerContact),
      withLatestFrom(this.customerCaseFacade.customerCase$),
      switchMap(
        ([
          {
            payload: { customerCaseId, ...reqPayload }
          },
          customerCase
        ]: [{ payload: UpdateCustomerRequest }, CustomerCase]) => {
          let observable: Observable<CustomerObjects>;
          if (customerCase.customer) {
            observable = this.customerService.updateCustomer(customerCaseId, reqPayload);
          } else {
            observable = this.customerService.createCustomer(customerCaseId, reqPayload);
          }
          return observable.pipe(
            map(() => ContactDataActions.createOrUpdateCustomerContactSuccess()),
            catchError((error: HttpErrorResponse) =>
              of(ContactDataActions.updateCustomerContactFailure({ error: errorToString(error) }))
            )
          );
        }
      )
    )
  );

  public refetchCustomerCaseOnContactUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactDataActions.createOrUpdateCustomerContactSuccess),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      map(([_action, customerCaseId]: [Action, string]) =>
        CustomerCaseActions.fetchCustomerCase({ payload: customerCaseId })
      )
    )
  );

  public updateCustomerContactOpportunityFunnel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactDataActions.updateCustomerContactOpportunityFunnel),
      withLatestFrom(
        this.customerCaseFacade.customerCase$,
        this.contactDataFacade.driverTitle$,
        this.contactDataFacade.driverFirstname$,
        this.contactDataFacade.driverLastname$,
        this.contactDataFacade.email$,
        this.contactDataFacade.mobile$
      ),
      switchMap(
        ([_action, customerCase, titleDriver, firstName, lastName, email, phone]: [
          Action,
          CustomerCase,
          string,
          string,
          string,
          string,
          string
        ]) => {
          const reqPayload: UpdateCustomerOpportunityFunnelRequest = {
            customerCaseId: customerCase.id,
            driver: {
              contact: {
                titleDriver,
                firstName,
                lastName,
                title: titleDriver,
                email,
                phone
              },
              optInEmail: false,
              optInSms: false
            }
          };

          let observable: Observable<CustomerObjects>;
          if (customerCase.customer) {
            observable = this.customerService.updateCustomer(customerCase.id, reqPayload);
          } else {
            observable = this.customerService.createCustomer(customerCase.id, reqPayload);
          }
          return observable.pipe(
            map(() => ContactDataActions.updateCustomerContactOpportunityFunnelSuccess()),
            catchError((error: HttpErrorResponse) =>
              of(ContactDataActions.updateCustomerContactOpportunityFunnelFailure({ error: errorToString(error) }))
            )
          );
        }
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private customerCaseFacade: CustomerCaseFacade,
    private contactDataFacade: ContactDataFacade
  ) {}
}
