import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ContextService } from "@cg/olb/services";
import { ContextActions } from "@cg/olb/state";
import { AnalyticFacade } from "@cg/analytics";
import { UnifiedError } from "@cg/core/types";
import { errorToString } from "@cg/core/utils";
import { Context } from "@cg/olb/shared";
import { EntryChannel } from "@cg/shared";

@Injectable()
export class ContextEffects {
  public createContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContextActions.createContext),
      withLatestFrom(this.analyticFacade.initialPage$),
      switchMap(([{ payload }, initialPage]: [{ payload: { entryChannel: EntryChannel } }, string]) =>
        this.contextService
          .createContext({ entryChannel: payload.entryChannel, ...(initialPage && { initialPage }) })
          .pipe(
            map((context: Context) => ContextActions.createContextSuccess({ payload: context })),
            catchError((error: UnifiedError) =>
              of(ContextActions.createContextFailure({ error: errorToString(error) }))
            )
          )
      )
    )
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly contextService: ContextService,
    private readonly analyticFacade: AnalyticFacade
  ) {}
}
