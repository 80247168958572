import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { OverlayService } from "@cg/shared";
import { ExitOverlayComponent } from "../component/exit-overlay-original/exit-overlay.component";
import * as ExitOverlayActions from "./exit-overlay.actions";

@Injectable()
export class ExitOverlayEffects {
  public constructor(
    private actions$: Actions,
    private readonly overlayService: OverlayService
  ) {}

  public openExitOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExitOverlayActions.openExitOverlay),
        tap(() => {
          this.overlayService.open(ExitOverlayComponent);
        })
      ),
    {
      dispatch: false
    }
  );

  public closeExitOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExitOverlayActions.closeExitOverlay),
        tap(() => {
          this.overlayService.close();
        })
      ),
    {
      dispatch: false
    }
  );
}
