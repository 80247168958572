/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { filter, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { CustomerCaseActions, CustomerCaseFacade } from "@cg/olb/state";
import { GoogleTagManagerService, Page, TrackingEvent, TrackingService } from "@cg/analytics";
import { CustomerCaseTrackingService } from "@cg/olb/shared";
import { LicensePlateType, SwitchChannelPayload } from "@cg/shared";

@Injectable()
export class CustomerCaseTrackingEffects {
  public trackCustomerCaseId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.fetchCustomerCaseSuccess),
        take(1),
        switchMap(() =>
          this.googleTagManagerService.pageChanged$.pipe(
            filter((page: Page) => page !== null),
            take(1),
            withLatestFrom(this.customerCaseFacade.customerCaseId$),
            tap(([_, id]: [Page, string]) => this.customerCaseTrackingService.trackCustomerCaseId(id))
          )
        )
      ),
    { dispatch: false }
  );

  public trackChannelSwitchLpn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.switchChannel),
        tap(({ payload }: { payload: SwitchChannelPayload }) => {
          const eventLabel =
            payload.car.lpn.type === LicensePlateType.SPECIAL ? "special-license-plate" : "standard-license-plate";
          const event: Partial<TrackingEvent> = {
            eventCategory: "olb",
            eventAction: "license-plate-entry",
            eventLabel
          };

          this.trackingService.trackEvent(event);
        })
      ),
    { dispatch: false }
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly customerCaseTrackingService: CustomerCaseTrackingService,
    private readonly googleTagManagerService: GoogleTagManagerService,
    private readonly trackingService: TrackingService
  ) {}
}
